<template>
    <div>
        <app-page-header v-if="!showLoadingWindow"></app-page-header>
        <v-container grid-list-xl>
            <!-- če je application.permit_type.type == 1, potem parkirna vloga -->
            <edit-parking-application v-if="computedApplicationType == 1 && !digitalApplication" :uuid="uuid" :applicationId="computedApplicationId" :application="computedApplication"></edit-parking-application>
            
        </v-container>
    </div>
</template>

<script>
const EditParkingApplication = () => import('@/components/admin/EditParkingApplication.vue')
import { EventBus } from '@/main.js'

export default {
    components: {
        EditParkingApplication,
    },

    data: () => ({
        uuid: null,
        loading: true,
        applicationId: null,
        application: null,
        applicationType: null,
        digitalApplication: null
    }),

    watch: {
        
    },

    computed: {
        showLoadingWindow() {
            if(this.loading) {
                return true;
            }

            return false;
        },

        permitType() {
            if(this.application !== "undefined" && this.application !== null) {
                if(this.application.permit_type !== "undefined" && this.application.permit_type !== null) {
                    return this.application.permit_type.type
                }
            }

            return false
        },

        computedApplicationId() {
            return this.applicationId
        },

        computedApplication() {
            return this.application
        },

        computedApplicationType() {
            return this.applicationType
        }
    },

    methods: {
        getApplicationData(uuid) {
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o vlogi je v teku...')
            this.$store.commit('SET_LOADER', true)
            var vm = this
            this.$store.dispatch("EDIT_APPLICATION", uuid)
            .then(response => {
                //window.console.log(response.data.data)
                vm.application = response.data.data
                vm.applicationId = response.data.data.id
                vm.applicationType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type: null
                vm.digitalApplication = response.data.data.digital;
            })
            .catch(error => {
                window.console.log(error)
            })
            .finally(() => {
                setTimeout(() => { 
                    vm.loading = false
                    this.$store.commit('SET_LOADER', false) 
                }, 400)
            })
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        var vm = this
        this.uuid = this.$route.params.id
        if(this.uuid != null) {
            this.getApplicationData(this.uuid)
        }

        //refresh dovolilnice
        EventBus.$on('GET_APPLICATION_DATA', function(data){
            if(data != null && data != undefined) {
                vm.$store.dispatch("EDIT_APPLICATION", vm.uuid)
                .then(response => {
                    vm.application = response.data.data
                    vm.applicationId = response.data.data.id
                    vm.applicationType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type: null
                    setTimeout(() => {
                        EventBus.$emit('REFRESH_APPLICATION_DATA', {
                            success: true
                        })
                    }, 200)
                })
                .catch(error => {
                    window.console.error("### ERROR EditApplicationt@refreshApplicationData ###")
                    window.console.log(error)
                    EventBus.$emit('REFRESH_APPLICATION_DATA', {
                        success: false
                    })
                })
            } else {
                setTimeout(() => {
                    EventBus.$emit('REFRESH_APPLICATION_DATA', {
                        success: false
                    })
                }, 200)
            }
        })
    },

    destroyed() {
        EventBus.$off('REFRESH_APPLICATION_DATA')
    }
}

</script>

<style scoped>

</style>